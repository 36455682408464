import React from "react";
import { toServerUrl } from "../helpers/assetHelper";

function PartnersAndProducersCard({ src, link, name }) {
  return (
    <ContentWrapper link={link}>
      <img
        src={toServerUrl(src)}
        alt={name}
        className="w-[250px] h-[180px] bg-white"
        style={{
          objectFit: "contain",
        }}
      />
      <p className="text-white font-semibold p-2">{name}</p>
    </ContentWrapper>
  );
}

const ContentWrapper = ({ link, children }) => {
  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="bg-about-card-darker-green-color drop-shadow-lg overflow-hidden  m-3  rounded-md flex flex-col justify-center items-center"
      >
        {children}
      </a>
    );
  }
  return (
    <div className="bg-about-card-darker-green-color drop-shadow-lg overflow-hidden  m-3  rounded-md flex flex-col justify-center items-center">
      {children}
    </div>
  );
};

export default PartnersAndProducersCard;
