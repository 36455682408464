import React, { useState, useEffect, useRef } from "react";

const SvgMapModal = ({
  selectedPath,
  modalWidth = 400,
  modalContentComponent,
  handleCloseModal,
}) => {
  const ref = useRef(null);

  const show = !!selectedPath;

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [className, setClassName] = useState("");
  const [modalCurrentHeight, setModalCurrentHeight] = useState(0);

  useEffect(() => {
    const svgContainer = document.getElementById("map-svg-wrapper");

    if (svgContainer) {
      const svgRect = svgContainer?.getBoundingClientRect();

      const centerX = svgRect.height / 2 - modalCurrentHeight - 30;
      const centerY = svgRect.width / 2 - modalWidth / 2 + 10;

      setPosition({ x: centerX, y: centerY });
    }
  }, [modalWidth, modalCurrentHeight]);

  useEffect(() => {
    if (!show) {
      setClassName("");
    } else {
      setClassName("info-text-active");
    }
  }, [show]);

  useEffect(() => {
    if (selectedPath) {
      setModalCurrentHeight(ref.current?.clientHeight);
    }
  }, [selectedPath]);

  if (!show || !modalContentComponent(selectedPath)) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={`info-text ${className}`}
      style={{ left: position.y, top: position.x, width: modalWidth }}
    >
      {handleCloseModal && <button onClick={handleCloseModal}>X</button>}
      {/* content start  */}
      <div className="modal-content-wrapper">
        {modalContentComponent && modalContentComponent(selectedPath)}
      </div>
      {/* content end  */}

      <div className="arrow-down"></div>
    </div>
  );
};

export default SvgMapModal;
