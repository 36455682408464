import React from "react";
import Department from "./Department";
import { useNavigate } from "react-router-dom";
import { departmentsConfig } from "../configs/departmentsConfig";
import { useTranslation } from "react-i18next";

function AboutCard() {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const handleGoToEventPage = (e, url) => {
    e.preventDefault();
    navigate(url);
  };

  const getBackgroundColor = (idx) => {
    if (idx === 0) {
      return "bg-primary-color";
    }
    if (idx === 1) {
      return "bg-about-card-darker-green-color";
    }
    if (idx === 2) {
      return "bg-about-card-darkest-green-color";
    }
  };

  return (
    <div className="max-w-7xl mx-0 sm:mb-10 sm:mx-14 xl:mx-auto mt-[-300px]  flex flex-wrap lg:flex-nowrap flex-row justify justify-evenly drop-shadow-sm sm:drop-shadow-md py-[24px]">
      {departmentsConfig.map((department, idx) => {
        const departmentDescription = department.descriptions.map(
          (description, idx) => {
            return <p key={idx}>{translate(description)}</p>;
          }
        );
        return (
          <Department
            key={department.id}
            image={department.icon}
            title={translate(department.title)}
            description={departmentDescription}
            url={department.url}
            className={`${getBackgroundColor(idx)} ${
              idx === 1 ? "lg:mb-[-40px]" : "lg:mb-[10px]"
            } `}
            buttonText="VEZI MAI MULT"
            onClick={(e) => handleGoToEventPage(e, department.url)}
          />
        );
      })}
    </div>
  );
}

export default AboutCard;
