import React from "react";
import CompanyNumbers from "./CompanyNumbers";

function CompanyCard({ className, companyNumbers }) {
  return (
    <div
      className={`${className} flex flex-row justify-evenly max-w-full mx-auto h-fit backdrop-blur-sm drop-shadow  mb-[50px] rounded-md`}
    >
      {companyNumbers.map((card, idx) => {
        return (
          <CompanyNumbers
            key={`company-${idx}`}
            image={card.icon}
            number={card.number}
            text={card.text}
          />
        );
      })}
    </div>
  );
}

export default CompanyCard;
