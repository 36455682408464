import React, { useState, useEffect } from "react";
import AnimatedNumbers from "react-animated-numbers";

interface AnimatedNumberProps {
  number: number;
  getDuration?: (index: number) => number;
  animationType?: "decay" | "spring" | "keyframes" | "tween" | "inertia";
  fontSize?: number;
}

function AnimatedNumber({
  number,
  duration = 0.3,
  animationType = "spring",
  fontSize = 30,
  className,
}: AnimatedNumberProps) {
  const [numberValue, setNumberValue] = useState(0);

  useEffect(() => {
    setNumberValue(number);
  }, [number]);

  return (
    <AnimatedNumbers
      className={`text-white drop-shadow font-semibold w-100 ${className}`}
      transitions={(index) => ({
        type: animationType,
        duration: index + duration,
      })}
      animateToNumber={numberValue}
      fontStyle={{
        fontSize,
      }}
    />
  );
}

export default AnimatedNumber;
