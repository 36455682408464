import React from "react";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import KTSVG from "../utils/KTSVG";

function Footer() {
  const { t: translate } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-primary-color text-[.75em]">
      <div
        className="flex flex-col items-center justify-evenly  py-[40px] 
      lg:flex-row"
      >
        <div
          className="w-full flex flex-col justify-center items-center
            lg:flex-row
            lg:items-start 
            lg:justify-evenly text-center lg:text-left"
        >
          <div className="flex flex-col text-white mb-5">
            <Link
              to="/"
              className="bg-white rounded-md flex items-center justify-center py-2 mb-2"
            >
              <KTSVG className="w-[110px]" path="/assets/logo.svg" />
            </Link>

            <h5 className="text-green-200 font-medium">
              © 2A Farm Distribution - {currentYear}
            </h5>
          </div>
          <div className="flex flex-col text-white  mb-5">
            <h5 className="text-green-200 font-medium  mb-[7px]">
              {translate("footer_section_1_title")}
            </h5>
            <a
              href="https://anpc.ro/"
              target="_blank"
              rel="noreferrer"
              alt="protectia-consumatorului"
              className="cursor-pointer hover:underline"
            >
              {translate("footer_section_1_subtitle_1")}
            </a>
            <Link to="/privacy-policy" className="hover:underline">
              {translate("footer_section_1_subtitle_2")}
            </Link>
            <Link to="/cookies-policy" className="hover:underline">
              {translate("footer_section_1_subtitle_3")}
            </Link>
          </div>

          <div className="flex flex-col text-white mb-5">
            <h5 className="text-green-200 font-medium mb-[7px]">
              {translate("footer_section_3_title")}
            </h5>
            <a
              href="http://comenzi.2afarm.ro/"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer hover:underline"
            >
              {translate("footer_section_3_subtitle_1")}
            </a>
            <Link to="/partners" className="cursor-pointer hover:underline">
              {translate("footer_section_3_subtitle_2")}
            </Link>
            <Link to="/producers" className="cursor-pointer hover:underline">
              {translate("footer_section_3_subtitle_3")}
            </Link>
            <Link to="/contact" className="cursor-pointer hover:underline">
              {translate("footer_section_3_subtitle_4")}
            </Link>
          </div>

          <div className="flex flex-col mb-5">
            <a
              href="https://anpc.ro/ce-este-sal/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={toAbsoluteUrl("/assets/anpc/ANPC-1.png")}
                width={200}
                alt="anpc1"
              />
            </a>

            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={toAbsoluteUrl("/assets/anpc/ANPC-2.png")}
                width={200}
                alt="anpc2"
                className="mt-3"
              />
            </a>
          </div>

          <div className="flex flex-row justify-center items-start mb-5">
            <a
              href="https://www.certificareiso.ro/certificat-iso-9001/"
              target="_blank"
              rel="noreferrer"
              className="mt-[-12px]"
            >
              <img
                src={toAbsoluteUrl("/assets/certifications/CERT-ISO9001.png")}
                width={80}
                alt="ISO9001"
              />
            </a>

            <a
              href="https://www.certificareiso.ro/certificat-iso-45001/"
              target="_blank"
              rel="noreferrer"
              className="mt-[-12px]"
            >
              <img
                src={toAbsoluteUrl("/assets/certifications/CERT-ISO45001.png")}
                width={80}
                alt="ISO45001"
              />
            </a>

            <a
              href="https://www.certificareiso.ro/certificat-iso-14001/"
              target="_blank"
              rel="noreferrer"
              className="ml-2"
            >
              <img
                src={toAbsoluteUrl("/assets/certifications/ISO14001.jpg")}
                width={80}
                alt="ISO14001"
              />
            </a>

            <a
              href="https://www.coface.ro/"
              target="_blank"
              rel="noreferrer"
              className="ml-2"
            >
              <img
                src={toAbsoluteUrl("/assets/certifications/COFACE.png")}
                width={100}
                alt="COFACE"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
