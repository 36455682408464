import React from "react";
import Content from "../Content";

function EventsComponent({
  backgroundImage,
  title,
  type,
  description,
  htmlDescriptions,
  link,
  buttonText,
  wrapperClassName,
  contentType,
  contentBackground,
  onClick,
}) {
  const getBackgroundImage = () => {
    if (backgroundImage) {
      return backgroundImage;
    }
    return "bg-gradient-to-r from-cyan-500 to-blue-500";
  };

  const getContentBackground = () => {
    if (contentBackground) {
      return contentBackground;
    }
    return "bg-white backdrop-blur-sm";
  };

  return (
    <div
      className={`flex justify-center ${
        type === "reverse" ? "lg:justify-start" : "lg:justify-end"
      } ${getBackgroundImage()} bg-center bg-cover bg-no-repeat 
       min-h-[420px] min-lg:h-[700px] duration-500 
       z-10 relative
      before:content-['']
      before:absolute
      before:inset-0
      before:block
      before:bg-gradient-to-tr
      before:from-gray-700
      before:to-gray-800
      before:opacity-40
      before:z-[-5]
       ${wrapperClassName}`}
      style={{
        backgroundImage: `${backgroundImage ? `url(${backgroundImage})` : ""}`,
      }}
    >
      <Content
        contentType={contentType}
        wrapperClassName={`${getContentBackground()} flex flex-col justify-center items-center min-h-full sm:w-2/4 lg:w-1/3 sm:mx-[60px] bg-opacity-5 sm:bg-opacity-70`}
        contentClassName="p-10 lg:p-10 xl:p-16 flex flex-1 flex-col justify-center items-start"
        title={title}
        description={description}
        htmlDescriptions={htmlDescriptions}
        link={link}
        descriptionColor={"text-white md:text-black"}
        descriptionClass="line-clamp-4"
        buttonText={buttonText}
        onClick={onClick}
      />
    </div>
  );
}

export default EventsComponent;
