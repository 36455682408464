import React, { useRef, useEffect, Fragment } from "react";

const VideoComponent = ({ videoUrl, className }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      const player = new window.YT.Player(iframeRef.current, {
        events: {
          onReady: (event) => {
            const observer = new IntersectionObserver(
              ([entry]) => {
                if (!entry.isIntersecting) {
                  player.pauseVideo();
                } else {
                }
              },
              { threshold: 0.25 }
            );

            observer.observe(iframeRef.current);

            return () => {
              observer.unobserve(iframeRef.current);
            };
          },
        },
      });
    };

    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }
  }, []);

  return (
    <Fragment>
      <iframe
        ref={iframeRef}
        className={className}
        src={`${videoUrl}?enablejsapi=1&autoplay=0&mute=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      ></iframe>
    </Fragment>
  );
};

export default VideoComponent;
