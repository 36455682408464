export const companyNumbersConfig = [
  {
    id: 1,
    icon: "/assets/clients.svg",
    number: "1400",
    text: "company_numbers_clients_text",
  },
  {
    id: 2,
    icon: "/assets/work-points.svg",
    number: "2000",
    text: "company_numbers_pharmacies_text",
  },
  {
    id: 3,
    icon: "/assets/products.svg",
    number: "4600",
    text: "company_numbers_products_text",
  },
  {
    id: 4,
    icon: "/assets/providers.svg",
    number: "250",
    text: "company_numbers_producers_text",
  },
];
