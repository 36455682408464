import React from "react";
import ReactCookieConsent from "react-cookie-consent";

function CookieConsent() {
  return (
    <ReactCookieConsent
      location="bottom"
      buttonText="SUNT DE ACORD"
      cookieName="2aFarmCookieConsent"
      style={{
        display: "flex",
        position: "fixed",
        alignItems: "center",
        color: "#388e70",
        background: "white",
        boxShadow: "0 0 15px rgba(0,0,0,0.5)",
        maxWidth: 500,
        margin: 20,
        padding: 5,
        borderRadius: 5,
      }}
      buttonStyle={{
        backgroundColor: "#388e70",
        color: "white",
        fontSize: "13px",
      }}
      expires={150}
      onAccept={(acceptedByScrolling) => {}}
      onDecline={() => {}}
    >
      Acest site folosește cookie-uri și date personale pentru a vă îmbunătăți
      experiența de navigare. Continuarea utilizării presupune acceptarea lor.
    </ReactCookieConsent>
  );
}

export default CookieConsent;
