import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchGDPR } from "../redux/api/events.api";
import { SpinnerCircularFixed } from "spinners-react";

const privacyPolicySections = [
  {
    title: "policy_section_title_1",
    description: "policy_section_description_1",
  },
  {
    title: "policy_section_title_2",
    description: "policy_section_description_2",
  },
  {
    title: "policy_section_title_3",
    description: "policy_section_description_3",
  },
  {
    title: "policy_section_title_4",
    description: "policy_section_description_4",
  },
  {
    title: "policy_section_title_5",
    description: "policy_section_description_5",
  },
  {
    title: "policy_section_title_6",
    description: "policy_section_description_6",
  },
  {
    title: "policy_section_title_7",
    description: "policy_section_description_7",
  },
  {
    title: "policy_section_title_8",
    description: "policy_section_description_8",
  },
  {
    title: "policy_section_title_9",
    description: "policy_section_description_9",
  },
  {
    title: "policy_section_title_10",
    description: "policy_section_description_10",
  },
  {
    title: "policy_section_title_11",
    description: "policy_section_description_11",
  },
  {
    title: "policy_section_title_12",
    description: "policy_section_description_12",
  },
  {
    title: "policy_section_title_13",
    description: "policy_section_description_13",
  },
];

function PrivacyPolicy() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    events: {
      gdprData: { data, isLoading },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  useEffect(() => {
    dispatch(fetchGDPR());
  }, [dispatch]);

  return (
    <div className="min-h-[90vh] max-w-[1300px] mx-auto pt-[55px] px-3 my-[55px]">
      <h1 className="max-w-7xl mx-auto text-4xl md:text-5xl lg:text-6xl drop-shadow mb-5 font-bold text-center">
        {translate("policy_title")}
      </h1>

      {isLoading && (
        <div className="pb-[300px] pt-[55px] flex items-start justify-center ">
          <SpinnerCircularFixed
            size={100}
            thickness={157}
            speed={300}
            color="#44bb92"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      )}

      {!isLoading && (
        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
      )}

      {/* <p>{translate("policy_description")}</p>

      <div className="py-5">
        {privacyPolicySections.map((section, idx) => {
          return (
            <div key={`section-${idx}`} className="mb-4">
              <h1 className="text-3xl font-bold mb-2">
                {`${idx + 1}. ${translate(section.title)}`}
              </h1>
              <p>{translate(section.description)}</p>
            </div>
          );
        })}
      </div> */}
    </div>
  );
}

export default PrivacyPolicy;
