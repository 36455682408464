import "./App.css";
import "swiper/css";
import "swiper/css/bundle";

import "./lang/i18n";

import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Career from "./pages/Career";
import Contact from "./pages/Contact";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer";
import CareerJobPage from "./pages/CareerJobPage";
import Events from "./pages/Events";
import EventMainPage from "./pages/EventMainPage";
import Representatives from "./pages/Representatives";
import Distribution from "./pages/Distribution";
import Logistics from "./pages/Logistics";
import Fleet from "./pages/Fleet";
import Partners from "./pages/Partners";
import Producers from "./pages/Producers";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useDispatch } from "react-redux";
import {
  fetchNumbersData,
  fetchPartnersData,
  fetchProducersData,
  fetchRepresentativesData,
} from "./redux/api/events.api";

import CookiesPolicy from "./pages/CookiesPolicy";
import CookieConsent from "./components/CookieConsent";
import ExternalNavbar from "./components/ExternalNavbar";

function App() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNumbersData());
    dispatch(fetchRepresentativesData());
    dispatch(fetchPartnersData());
    dispatch(fetchProducersData());
  }, [dispatch]);

  return (
    <div className="font-montserrat max-w-[2600px] mx-auto">
      <CookieConsent />
      <BrowserRouter>
        <ScrollToTop />

        <ExternalNavbar ordersButtonText={translate("navbar_orders")} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/:id" element={<CareerJobPage />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventMainPage />} />
          <Route path="/representatives" element={<Representatives />} />
          <Route path="/distribution" element={<Distribution />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/fleet" element={<Fleet />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/producers" element={<Producers />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />

          <Route path="*" element={<Home />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
