import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { toServerUrl } from "../helpers/assetHelper";

function SliderWithText({ title, sliderData, className }) {
  return (
    <div
      className={`select-none max-w-full p-[20px] md:p-[40px] lg:py-[60px] ${className}`}
    >
      <h2 className="text-primary-color text-center text-xl lg:text-2xl xl:text-3xl font-bold">
        {title}
      </h2>
      <div className="max-w-7xl mx-auto py-[30px]">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          effect="fade"
          loop={true}
          draggable
          breakpoints={{
            // when window width is >= 425px
            425: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        >
          {sliderData.map((img, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div className="h-[200px] flex flex-row justify-center items-center ">
                  <img
                    alt={`partners-${idx}`}
                    className=" h-[160px]"
                    src={toServerUrl(img.src)}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default SliderWithText;
