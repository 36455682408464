import React from "react";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import PartnersAndProducersCard from "../components/PartnersAndProducersCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";

function Producers() {
  const { t: translate } = useTranslation();

  const {
    events: {
      producersData: { data: producersData, isLoading: isLoadingProducers },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  return (
    <div className="min-h-screen pt-[55px]">
      <HeroComponent
        height={250}
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0525.jpg")}
        title={translate("producers_page_title")}
      />

      {isLoadingProducers && (
        <div className="pb-[300px] pt-[55px] flex items-start justify-center ">
          <SpinnerCircularFixed
            size={100}
            thickness={157}
            speed={300}
            color="#44bb92"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      )}

      {!isLoadingProducers && (
        <div className="flex flex-row justify-center items-center flex-wrap max-w-[1300px] mx-auto my-10">
          {producersData.map((producer, idx) => {
            return (
              <PartnersAndProducersCard
                key={`${producer.name}-${idx}`}
                src={producer.src}
                link={producer.link}
                name={producer.name}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Producers;
