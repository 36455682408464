import React, { useEffect, useState } from "react";
import HeroComponent from "../components/PagesComponents/HeroComponent";

import useRoutesHelper from "../hooks/useRoutesHelper";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { departmentsConfig } from "../configs/departmentsConfig";
import { useTranslation } from "react-i18next";

function Distribution() {
  const { currentUrl } = useRoutesHelper();
  const { t: translate } = useTranslation();

  const [distributionPage, setDistributionPage] = useState("");

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("/");

    const getSlicedString = () => {
      if (paramsStartingIndex > 0) {
        return currentUrl.substring(paramsStartingIndex);
      }
      return currentUrl;
    };

    setDistributionPage(`/${getSlicedString()}`);
  }, [currentUrl]);
  if (!distributionPage) {
    return;
  }

  const currentDepartment = departmentsConfig.find((item) => {
    return item.url === distributionPage;
  });

  if (!currentDepartment) {
    return;
  }

  const { descriptions, title, pagePicture } = currentDepartment;

  const departmentDescription = descriptions.map((description, idx) => {
    return (
      <p key={idx} className="flex flex-col">
        {translate(description)}
      </p>
    );
  });

  return (
    <div className="min-h-screen pt-[55px]">
      <HeroComponent
        height={650}
        title={translate(title)}
        backgroundImage={toAbsoluteUrl(pagePicture)}
      />
      {currentDepartment && (
        <div className="p-[15px] sm:p-[20px] lg:p-[40px] max-w-5xl mx-auto select-none">
          <div className="bg-gray-50 flex flex-col justify-center items-center lg:justify-between rounded-xl p-2 sm:p-10 ">
            <div className="w-full flex flex-col justify-between">
              <p className="text-justify text-sm xl:text-base font-medium ">
                {departmentDescription}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Distribution;
