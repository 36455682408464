import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ro from "./ro.json";
import it from "./it.json";
import fr from "./fr.json";
import gr from "./gr.json";
import pl from "./pl.json";
import { toAbsoluteUrl } from "../helpers/assetHelper";

const defaultLanguage = "ro";

export const languages = [
  {
    code: "en",
    name: "English",
    country_flag: toAbsoluteUrl("/assets/flags/en.svg"),
  },
  {
    code: "ro",
    name: "Romana",
    country_flag: toAbsoluteUrl("/assets/flags/ro.svg"),
  },
  {
    code: "it",
    name: "Italy",
    country_flag: toAbsoluteUrl("/assets/flags/it.svg"),
  },
  {
    code: "fr",
    name: "France",
    country_flag: toAbsoluteUrl("/assets/flags/fr.svg"),
  },
  {
    code: "gr",
    name: "Germany",
    country_flag: toAbsoluteUrl("/assets/flags/gr.svg"),
  },
  {
    code: "pl",
    name: "Poland",
    country_flag: toAbsoluteUrl("/assets/flags/pl.svg"),
  },
];

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  ro: {
    translation: ro,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  gr: {
    translation: gr,
  },
  pl: {
    translation: pl,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
