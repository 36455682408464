import { createSlice } from "@reduxjs/toolkit";

import {
  fetchEventsData,
  fetchNumbersData,
  fetchJobsData,
  fetchRepresentativesData,
  fetchPartnersData,
  fetchProducersData,
  fetchGDPR,
  fetchCookies,
} from "../api/events.api";

import { companyNumbersConfig } from "../../configs/companyNumbersConfig";

export const defaultStateEvents = {
  numbers: companyNumbersConfig,
  eventsData: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      per_page: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  eventData: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  jobsData: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      per_page: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  jobData: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  repsentativesData: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  partnersData: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  producersData: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  gdprData: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  cookiesData: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const events = createSlice({
  name: "events",
  initialState: defaultStateEvents,
  reducers: {
    setEventData: (state, action) => {
      return {
        ...state,
        eventData: {
          ...state.eventData,
          data: action.payload,
        },
      };
    },
    setJobData: (state, action) => {
      return {
        ...state,
        jobData: {
          ...state.jobData,
          data: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // events start
    builder.addCase(fetchEventsData.pending, (state) => {
      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchEventsData.rejected, (state, action) => {
      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchEventsData.fulfilled, (state, action) => {
      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // events end

    // numbers start
    builder.addCase(fetchNumbersData.fulfilled, (state, action) => {
      return {
        ...state,
        numbers: action.payload,
      };
    });
    // numbers end

    // jobs start
    builder.addCase(fetchJobsData.pending, (state) => {
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchJobsData.rejected, (state, action) => {
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchJobsData.fulfilled, (state, action) => {
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // jobs end

    // representatives start
    builder.addCase(fetchRepresentativesData.pending, (state) => {
      return {
        ...state,
        repsentativesData: {
          ...state.repsentativesData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchRepresentativesData.rejected, (state, action) => {
      return {
        ...state,
        repsentativesData: {
          ...state.repsentativesData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchRepresentativesData.fulfilled, (state, action) => {
      return {
        ...state,
        repsentativesData: {
          ...state.repsentativesData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // representatives end

    // partners start
    builder.addCase(fetchPartnersData.pending, (state) => {
      return {
        ...state,
        partnersData: {
          ...state.partnersData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchPartnersData.rejected, (state, action) => {
      return {
        ...state,
        partnersData: {
          ...state.partnersData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchPartnersData.fulfilled, (state, action) => {
      return {
        ...state,
        partnersData: {
          ...state.partnersData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // partners end

    // producers start
    builder.addCase(fetchProducersData.pending, (state) => {
      return {
        ...state,
        producersData: {
          ...state.producersData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchProducersData.rejected, (state, action) => {
      return {
        ...state,
        producersData: {
          ...state.producersData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchProducersData.fulfilled, (state, action) => {
      return {
        ...state,
        producersData: {
          ...state.producersData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // producers end

    // gdpr start
    builder.addCase(fetchGDPR.pending, (state) => {
      return {
        ...state,
        gdprData: {
          ...state.gdprData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchGDPR.rejected, (state, action) => {
      return {
        ...state,
        gdprData: {
          ...state.gdprData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchGDPR.fulfilled, (state, action) => {
      return {
        ...state,
        gdprData: {
          ...state.gdprData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // gdpr end

    // cookies start
    builder.addCase(fetchCookies.pending, (state) => {
      return {
        ...state,
        cookiesData: {
          ...state.cookiesData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchCookies.rejected, (state, action) => {
      return {
        ...state,
        cookiesData: {
          ...state.cookiesData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload?.message,
        },
      };
    });
    builder.addCase(fetchCookies.fulfilled, (state, action) => {
      return {
        ...state,
        cookiesData: {
          ...state.cookiesData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // cookies end
  },
});

export const { setEventData, setJobData } = events.actions;

export default events.reducer;
