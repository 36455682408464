import React from "react";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import AnimatedNumber from "./AnimatedNumber";
import { useTranslation } from "react-i18next";

function CompanyNumbers({ image, number, text, className }) {
  const { t: translate } = useTranslation();

  return (
    <div className={`${className} mx-[30px] py-[24px] `}>
      <div className="flex flex-col xl:flex-row text-center items-center justify-center select-none px-[30px]">
        <span className="bg-primary-color rounded-full mr-2">
          <img
            src={toAbsoluteUrl(image)}
            alt="pictures"
            width={100}
            className="p-5 pointer-events-none "
          />
        </span>

        <div className="flex flex-col justify-center items-center">
          <AnimatedNumber number={number} />

          <p className="text-[18px] text-secondary-color drop-shadow font-semibold">
            {translate(text)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyNumbers;
