import React from "react";
import Content from "../Content";
import VideoComponent from "../VideoComponent";

function VideoAndContentComponent({
  title,
  description,
  descriptions,
  descriptionClass,
  buttonText,
  videoUrl,
  type,
  contentType,
  onClick,
  wrapperClassName,
}) {
  return (
    <div
      className={`${wrapperClassName} p-[16px] sm:p-[40px] lg:p-[60px] flex flex-col justify-between items-center ${
        type === "reverse"
          ? "lg:items-center lg:flex-row-reverse"
          : "lg:items-center lg:flex-row"
      }  `}
    >
      <Content
        wrapperClassName={` w-11/12 lg:w-1/3 h-full ${
          type === "reverse" ? "" : "lg:mr-16"
        }`}
        contentType={contentType}
        title={title}
        description={description}
        descriptions={descriptions}
        buttonText={buttonText}
        onClick={onClick}
        descriptionClass={descriptionClass}
      />

      <VideoComponent
        videoUrl={videoUrl}
        className="w-full lg:w-4/6 h-[300px] md:h-[450px] mt-5 lg:mt-0"
      />
    </div>
  );
}

export default VideoAndContentComponent;
